
export default {
	name: 'addProject',
	data() {
		return {
			uploadUrl: XyhConf.HglSHost + '/pc/uploadFile',
			freeProfiles: [],

			sex: 'girl',
			removeAlert: false,
			removeItemIndex: "",
			addCRCAlert: false,
			token: '',
			inquireValue: '',
			user: [],
			tabel: '',
			CRCList: [], //已选CRC人员
			crcIDList: [],
			cIdList: '',
			addAndEidt: "",
			smoList: [], //smo列表
			smoCorporation: '', //smo公司//下拉框
			projectName: '', //项目名称下拉框
			sponsor: '', //申办方下拉框
			adaptation: '', //适应症
			office: '', //所属科室
			proSelVelue: '', //项目名称的值
			shortName: '', //项目简称
			proSelId: '', //项目名称的id
			sponsorName: '', //申办方的值
			projectNumber: '', //项目编号
			sponsorId: '', //申办方ID
			diseaseId: '', //适应症ID
			symptomName: '', //适应症的值
			departmentId: '', //所属科室id
			departmentVelue: [], //所属科室的值
			startTime: '', //启动时间
			endTime: '', //结束时间
			userList: [], //拼接数组
			userNewList: [], //拼接新增数组
			projectId: '',
			removeId: '',
			CrcstartTime: [], //添加crc开始时间
			CrcendTime: [], //添加crc结束时间
			changeTimeId: '', //修改时间的id
			crcIDListRemove: [], //图标删除id
			saveOkAlert: false, //保存成功
			hoverNo: -1, //选中的添加样式
			timeEndStart: false, //时间规范
			timeAndOut: true, //crc时间规范
			tabelNothing: false, //搜索后的提示
			//				roles: 1, //角色id
			leadList: [], //主负责人列表
			leadProId: '', //改变下拉框之前的id
			instId: '', //机构ID
			interviewRight: true, //添加访视板块
			schemeList: [], //方案
			examine: {
				workName: '', //访视名称
				workCycle: '', //访视周期
				type: '', //访视类型
				schemeId: '', //方案id
				workId: '', //访视ID
				examineList: [{ //检查日期列表
					id: '',
					timeOfAction: 0,
					isDelete: '1', //1存在2已删除
					windowNum: '1',
					windowType: '1', //1+-,2+,3-
					examineItemList: []
				}]
			},
			options: [{
				value: 1,
				label: '筛选期'
			}, {
				value: 2,
				label: '试验期'
			}, {
				value: 3,
				label: '终止期'
			}, {
				value: 4,
				label: '随访期'
			},],
			examineItemAlter: false,
			examineAlterMsg: [], //弹框检查项
			labelList: [], //弹框检查类内容
			examineChoiceList: [], //已选检查项列表
			examineChoiceIdList: [], //已选检查项id
			classListActive: '0',
			importSchemeAlter: false,
			addSchemeAlter: false,
			addSchemeAlterBtn: '',//用来判断是方案复制还是新建
			addSchemeAlterId: '',//获取方案复制还id
			schemeName: '', //新建方案名称
			fileValue: '选择导入文件', //导入文件名
			examinItemIndex: '', //点击添加检查项的检查日index
			id: '', //点击添加检查项的检查日id
			fileValue2: '', //导入参数
			httpHeader: {
				token: this.getCookie("token")
			},
			importSchemeErrAlter: false,//导入失败弹框
			importSchemeErrAlterList: [],//导入失败内容
			examineDay: true,//检查日期day验证能不能保存
			saveProjectisclick: true,//项目保存防止连续点击
			importSchemeSaveisclick: true,//方案名称添加防止连续点击
			fullscreenLoading: false,//导入过度效果
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.instId = this.getCookie('instId');
		this.projectId = this.$route.params.id == 0 ? "" : this.$route.params.id;
		this.addAndEidt = this.$route.params.isEidt;
		this.getprojectName();
		this.getsponsor();
		this.getadaptation();
		this.getoffice();
		var _this = this;
		if (_this.addAndEidt == 1) {
			_this.getEidtProject()
			_this.saveOkAlert = true
		}
	},
	directives: {
		"focus": function (el, binding) {
			if (binding.value) {
				el.focus();
			}
		}
	},

	methods: {
		getEidtProject() {
			var _this = this;
			this.cIdList = this.crcIDList.join(',')
			_this.$http.post('/API/PLAT/project', {
				pid: _this.projectId,
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.proSelVelue = response.data.data.projectName;
						_this.shortName = response.data.data.shortName
						_this.sponsorName = response.data.data.sponsorName;
						_this.projectNumber = response.data.data.projectNumber;
						_this.symptomName = response.data.data.symptomName;
						_this.departmentVelue = response.data.data.departmentIds;
						_this.startTime = new Date(response.data.data.startTime).getTime();
						_this.endTime = new Date(response.data.data.endTime).getTime();
						_this.proSelId = response.data.data.projectNumber;
						_this.sponsorId = response.data.data.bidId;
						_this.diseaseId = response.data.data.diseaseId;
						_this.departmentId = response.data.data.departmentId;
						for (let i = 0; i < response.data.data.schemeList.length; i++) {
							response.data.data.schemeList[i].schemeNameInput = false;
							response.data.data.schemeList[i].focusState = false;
						}
						_this.schemeList = response.data.data.schemeList;
						//123
						for (let i = 0; i < response.data.data.projectCrcList.length; i++) {
							let id = String(response.data.data.projectCrcList[i].id)
							//								_this.CRCList.push(response.data.data.projectCrcList[i])
							_this.crcIDList.push(id)
							if (response.data.data.projectCrcList[i].role == 1) {
								_this.leadList.push({
									'name': response.data.data.projectCrcList[i].name,
									'value': Number(response.data.data.projectCrcList[i].crcUserId)
								});
							}
						}
						_this.user = response.data.data.projectCrcList;
					}
				})
		},
		//项目名称下拉框
		getprojectName() {
			var _this = this;
			_this.$http.post('/API/PLAT/proLibList', {}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.projectName = response.data.data;
					}
				})
		},
		//申办方下拉框
		getsponsor() {
			var _this = this;
			_this.$http.post('/API/PLAT/bidList', {}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.sponsor = response.data.data;
					}
				})
		},
		//适应症下拉框
		getadaptation() {
			var _this = this;
			_this.$http.post('/API/PLAT/diseaseList', {}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.adaptation = response.data.data;
					}
				})
		},
		//所属科室
		getoffice() {
			var _this = this;
			_this.$http.post('/API/PLAT/departmentList', {}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//													console.log(response)
						_this.office = response.data.data;
					}
				})
		},
		//结束日期提示
		timeChange() {
			if (this.endTime < this.startTime) {
				this.timeEndStart = false;
				this.defeated("结束日期不能小于启动日期")
			} else {
				this.timeEndStart = true;
			}
		},
		//介入/退出日期提示
		crcTimeChange(item) {
			var _this = this;
			if ((new Date(item.andTime).getTime()) > (new Date(item.outTime).getTime())) {
				this.timeAndOut = false;
				this.defeated("退出日期不能小于介入日期")
			} else {
				this.timeAndOut = true;
			}
			for (let i = 0; i < _this.user.length; i++) {
				if (_this.user[i].id == item.id) {
					_this.user[i].andTime = _this.formatDate(new Date(item.andTime));
					_this.user[i].outTime = _this.formatDate(new Date(item.outTime));
				}
			}
		},
		//获取crc角色id
		changePrincipalId(item, index) {
			var _this = this;
			var status;
			//				item.leadList=[];
			if (item.role == 1) {
				_this.leadList.push({ 'name': item.name, 'value': item.id });
			} else {
				item.principalId = "";
				for (let i = 0; i < _this.leadList.length; i++) {
					if (_this.leadList[i].value == item.id) {
						_this.leadList.splice(i, 1)
						//							item.principalId = "";
					}
				}
			}
			//如果协助者选了负责人后又改成普通者或负责人，之前选的负责人要恢复状态
			if (item.principalId && item.principalId != 2) {
				for (let i = 0; i < _this.user.length; i++) {
					if (_this.user[i].id == item.principalId) {
						_this.user[i].countAssist = _this.user[i].countAssist - 1;
						//							item.principalId = "";
					}
				}
			}
		},
		focusStatusId(item) { //鼠标聚焦拿
			var _this = this;
			this.leadProId = item.principalId;
		},
		proStatusId(item) {
			this.leadProId = item.principalId;
		},
		//选择主负责人
		changeStatusId(item) {
			var _this = this
			if (_this.leadProId != item.principalId) { //改变之前的值和改变后的值不一样
				for (let i = 0; i < _this.user.length; i++) {
					_this.user[i].countAssist = Number(_this.user[i].countAssist)
					if (_this.user[i].id == _this.leadProId) {
						_this.user[i].countAssist = _this.user[i].countAssist - 1;
					} else if (_this.user[i].id == item.principalId) {
						_this.user[i].countAssist = _this.user[i].countAssist + 1;
					}
				}
			}
		},
		confirmSava() {
			var _this = this;
			var save = true;
			for (let i = 0; i < _this.user.length; i++) {
				if (_this.user[i].role == '2' && _this.user[i].principalId == "") {
					_this.defeated("请填写完整信息")
					save = false;
				}
			}
			if (save) {
				this.$confirm('此操作crc关联不可修改, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.saveCRC();
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消保存'
					});
				});
			}
		},
		saveProject() {
			var _this = this;
			if (_this.saveProjectisclick) {
				_this.saveProjectisclick = false;
				if (_this.startTime && _this.proSelVelue && _this.shortName && _this.sponsorName && _this.symptomName && _this.departmentVelue.length) {
					_this.$http.post('/API/PLAT/addProject', {
						pid: _this.projectId,
						projectName: _this.proSelVelue,
						shortName: _this.shortName,
						sponsorName: _this.sponsorName,
						projectNumber: _this.projectNumber,
						symptomName: _this.symptomName,
						departmentId: _this.departmentVelue,
						startTime: _this.formatDate(new Date(_this.startTime)),
					}, {
						headers: {
							"token": _this.token
						}
					})
						.then(function (response) {
							if (response.data.code == 200) {
								_this.saveOkAlert = true;
								_this.succeed("保存成功")
								_this.projectId = response.data.data.pid;
								setTimeout(function () {
									//									console.log(_this.saveProjectisclick)
									_this.saveProjectisclick = true;
								}, 500)
							} else {
								_this.defeated(response.data.detail)
								setTimeout(function () {
									_this.saveOkAlert = true;
									_this.saveProjectisclick = true;
								}, 2000)
							}
						})
				} else {
					_this.defeated("请填写完整信息")
					_this.saveProjectisclick = true;
				}
			}
		},
		saveCRC() {
			var _this = this;
			_this.$http.post('/API/PLAT/addProjectConnectCrc', {
				pid: _this.projectId,
				crcList: _this.user,
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.succeed("CRC保存成功")
						_this.getEidtProject()
					}
				})
		},
		remove(index, item) {
			var _this = this;
			var msg = '';
			var msg2 = '';
			_this.removeItemIndex = index;
			_this.removeId = String(item.crcUserId);
			if (item.state == 1) {
				msg = '确定该CRC退出项目？'
				msg2 = '退出项目成功'
			} else {
				msg = '是否删除该CRC？'
				msg2 = 'CRC删除成功'
			}
			this.$confirm(msg, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				if (item.state == 1) {
					_this.$http.post('/API/PLAT/outProject', {
						pid: _this.projectId,
						crcUserId: _this.removeId
					}, {
						headers: {
							"token": _this.token
						}
					})
						.then(function (response) {
							if (response.data.code == 200) {
								_this.succeed(msg2)
								_this.getEidtProject()
							}
						})
				} else {
					for (let i = 0; i < _this.user.length; i++) {
						if (_this.user[i].principalId == _this.removeId) { //如果该人为负责人并又协助者的时候删除要把协助者下拉框置空
							_this.user[i].countAssist = 0;
							_this.user[i].principalId = "";
						}
					}
					//删除
					_this.user.splice(_this.removeItemIndex, 1);
					_this.CRCList.splice(_this.removeItemIndex, 1);
					_this.crcIDList.splice(_this.removeItemIndex, 1);
					for (let i = 0; i < _this.tabel.length; i++) {
						if (_this.tabel[i].id == _this.removeId) {
							_this.tabel[i].isDel = null;
							_this.tabel[i].isShow = null;
							_this.succeed(msg2)
						}
					}
				}
			}).catch(() => {
				_this.removeItemIndex = '';
				_this.removeId = "";
				_this.examineItemAlter = false;
				_this.importSchemeAlter = false;
				_this.addSchemeAlter = false;
			});
		},
		yes1(type) {
			var _this = this;
			_this.$http.post('/API/PLAT/deleteWork', {
				workId: _this.examine.workId,
				type: type
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.removeAlert = false;
						_this.getSchemeList()
						_this.succeed('删除成功')
					} else {
						_this.defeated(response.data.detail)
					}
				})
		},

		yes() {
			var _this = this;
			if (_this.user[_this.removeId].state == 1) {
				_this.$http.post('/API/PLAT/crcUserList', {
					pid: _this.projectId,
					instId: _this.removeId
				}, {
					headers: {
						"token": _this.token
					}
				}).then(function (response) {
					if (response.data.code == 200) {
						_this.succeed('退出项目成功')
						}
					})
			} else {
				for (let i = 0; i < _this.user.length; i++) {
					if (_this.user[i].principalId == _this.removeId) { //如果该人为负责人并又协助者的时候删除要把协助者下拉框置空
						_this.user[i].countAssist = 0;
						_this.user[i].principalId = "";
					}
				}
				//删除
				_this.user.splice(_this.removeItemIndex, 1);
				_this.CRCList.splice(_this.removeItemIndex, 1);
				_this.crcIDList.splice(_this.removeItemIndex, 1);
				for (let i = 0; i < _this.tabel.length; i++) {
					if (_this.tabel[i].id == _this.removeId) {
						_this.tabel[i].isDel = null;
						_this.tabel[i].isShow = null;
					}
				}
			}
		},

		no() {
			this.removeItemIndex = '';
			this.removeId = "";
			this.examineItemAlter = false;
			this.importSchemeAlter = false;
			this.addSchemeAlter = false;
			this.importSchemeErrAlter = false;
			this.removeAlert = false;
		},

		crcAlert() {
			var _this = this;
			_this.addCRCAlert = true;
			if (!_this.tabel) {
				_this.getSmoList()
				_this.$http.post('/API/PLAT/crcUserList', {
					enid: _this.smoCorporation,
					instId: _this.instId
				}, {
					headers: {
						"token": _this.token
					}
				})
					.then(function (response) {
						if (response.data.code == 200) {
							for (let i = 0; i < response.data.data.length; i++) {
								for (let j = 0; j < _this.user.length; j++) {
									if (_this.user[j].crcUserId == response.data.data[i].id) {
										response.data.data[i].isDel = true;
										response.data.data[i].isShow = true;
									}
								}
							}
							_this.tabel = response.data.data;
						}
					})
			}
		},
		getSmoList() {
			var _this = this;
			_this.$http.post('/API/PLAT/crcUserSmoList', {
				instId: _this.instId
			}, {
				headers: {
					"token": _this.token,
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.smoList = response.data.data;
					}
				})
		},
		inquire() {
			var _this = this;
			_this.$http.post('/API/PLAT/crcUserList', {
				name: _this.inquireValue,
				enid: _this.smoCorporation,
				instId: _this.instId
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						for (let i = 0; i < response.data.data.length; i++) {
							for (let j = 0; j < _this.user.length; j++) {
								if (_this.user[j].yn != 2) {
									if (_this.user[j].id == response.data.data[i].id) {
										response.data.data[i].isDel = true;
										response.data.data[i].isShow = true;
									}
								}
							}
							for (let k = 0; k < _this.CRCList.length; k++) {
								if (_this.CRCList[k].id == response.data.data[i].id) {
									response.data.data[i].isShow = true;
								}
							}
						}
						_this.tabel = response.data.data;
					}
				})

		},
		addCRCList(index, item, itemid) {
			var _this = this;
			_this.startTime = new Date(_this.startTime);
			var outTime = ((_this.startTime.getFullYear() + 2) + '-' + (_this.startTime.getMonth() + 1) + '-' + (_this.startTime.getDate()))
			if (_this.crcIDList.indexOf(String(itemid)) == -1) {
				_this.CRCList.push(item);
				_this.crcIDList.push(itemid);
				item.isShow = true;
				item.andTime = _this.formatDate(new Date(_this.startTime));
				item.outTime = _this.formatDate(new Date(outTime));
				item.principalId = '';
			}
		},
		removeCRCList(index, item) {
			var _this = this;
			this.CRCList.splice(index, 1);
			for (let i = 0; i < _this.tabel.length; i++) {
				if (_this.tabel[i].id == item.id) {
					_this.tabel[i].isShow = false;
				}
			}
			for (let i = 0; i < _this.crcIDList.length; i++) {
				if (_this.crcIDList[i] == item.id) {
					_this.crcIDList.splice(i, 1);
				}
			}
			item.isShow = false;
			item.isDel = false;
		},

		//项目人员保存
		crcAlertSave() {
			var _this = this;
			this.$confirm('此操作crc关联不可修改, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.save();
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消保存'
				});
			});
		},
		getSchemeList() {
			var _this = this;
			_this.$http.post('/API/PLAT/project', {
				pid: _this.projectId,
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						for (let i = 0; i < response.data.data.schemeList.length; i++) {
							response.data.data.schemeList[i].schemeNameInput = false;
						}
						_this.schemeList = response.data.data.schemeList;
					}
				})
		},
		editSchemeName(item) {
			item.schemeNameInput = true;
			item.focusState = true;
		},
		blurschemeNameInput(item) {
			item.schemeNameInput = false;
			item.focusState = false;
			this.addSchemeSave(2, item.name, item.id)
			this.getSchemeList()
		},
		//方案三个小点点击
		schemeEeditMenu(command) {
			var _this = this;
			var id = command.split(',')[0];
			var name = command.split(',')[1];
			var btn = command.split(',')[2];
			if (btn == '删除') {
				for (let i = 0; i < _this.schemeList.length; i++) {
					if (_this.schemeList[i].id == id) {
						if (_this.schemeList[i].state == 1) {
							_this.defeated('请先停用方案')
						} else {
							//								console.log(_this.schemeList[i].workList)
							_this.addSchemeSave(6, name, id)
						}
					}
				}
			} else if (btn == '复制') {
				_this.schemeName = name;
				_this.addSchemeAlterBtn = "复制"
				_this.addSchemeAlterId = id;
				_this.addSchemeAlter = true;
			} else if (btn == '停用') {
				_this.addSchemeSave(4, name, id)
			} else {
				_this.addSchemeSave(5, name, id)
			}
		},
		//访视详情页面
		toInterviewParticulars(item) {
			console.log(item)
			this.$router.push({
				name: "interviewParticulars",
				params: {
					id: item.id
				}
			})
		},
		//访视编辑回显数据接口
		getInterviewMsg(id, start) {
			var _this = this;
			var schemeId = _this.examine.schemeId;
			_this.$http.post('/API/PLAT/findWorkInfo', {
				workId: id, //访视id
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						for (let i = 0; i < response.data.data.examineList.length; i++) {
							response.data.data.examineList[i].isDelete = 1;
							response.data.data.examineList[i].windowType = String(response.data.data.examineList[i].windowType);
							for (let j = 0; j < response.data.data.examineList[i].examineItemList.length; j++) {
								response.data.data.examineList[i].examineItemList[j].type = 1;
								response.data.data.examineList[i].examineItemList[j].itemId = response.data.data.examineList[i].examineItemList[j].inspectionItemId;
							}
						}
						_this.examine = response.data.data;
						_this.examine.schemeId = schemeId;//方案id
						if (start == '复制') {
							_this.examine.workId = "";
							for (let i = 0; i < _this.examine.examineList.length; i++) {
								_this.examine.examineList[i].id = "";
								for (let j = 0; j < _this.examine.examineList[i].examineItemList.length; j++) {
									_this.examine.examineList[i].examineItemList[j].id = ""
								}
							}
						}
					}
				})
		},
		//访视三个小点点击
		interviewEeditMenu(command) {
			var _this = this;
			_this.examine.workId = command.split(',')[0]; //访视id
			_this.examine.schemeId = command.split(',')[1]; //方案id
			var btn = command.split(',')[2];
			if (btn == "复制") {
				_this.interviewRight = false;
				_this.getInterviewMsg(_this.examine.workId, '复制')
			} else if (btn == "编辑") {
				_this.interviewRight = false;
				_this.getInterviewMsg(_this.examine.workId, '编辑')
			} else {
				_this.removeAlert = true;
			}
		},
		alertYes() {
			var _this = this;
			this.addCRCAlert = false;
			_this.startTime = new Date(_this.startTime);
			var outTime = ((_this.startTime.getFullYear() + 2) + '-' + (_this.startTime.getMonth() + 1) + '-' + (_this.startTime.getDate()))
			for (let i = 0; i < _this.CRCList.length; i++) { //已选CRC
				_this.CRCList[i].role = 0; //角色默认值
				_this.CRCList[i].state = 2; //角色默认值
				_this.CRCList[i].countAssist = 0; //负责人的计数器
				_this.user.push(_this.CRCList[i])
			}
			for (let j = 0; j < _this.user.length; j++) { //添加的CRC
				for (let i = 0; i < _this.tabel.length; i++) { //选择crc列表不显示
					if (_this.user[j].id == _this.tabel[i].id) {
						_this.tabel[i].isDel = true;
						_this.tabel[i].countAssist = 0; //负责人的计数器
					}
				}
			}
			_this.CRCList = []; //每次添加完清空已选CRC
		},

		alertNo() {
			this.addCRCAlert = false;
		},

		toPrMan() {
			this.$router.push('/projectManage')
		},

		toInterviewRight(id) { //新增方案right
			var _this = this;
			this.examine.workId = ''; //清空访视id
			this.examine.schemeId = id;
			this.examine.workName = '';
			this.examine.workCycle = ''; //访视周期
			this.examine.type = ''; //访视类型
			this.examine.examineList = [];
			if (!_this.examine.examineList.length) {
				_this.examine.examineList.push({
					id: '',
					timeOfAction: 0,
					isDelete: '1', //1存在2已删除
					windowNum: '1',
					windowType: '1', //1+-,2+,3-
					examineItemList: []
				})
			}
			this.interviewRight = !this.interviewRight;
		},
		importScheme() {
			this.getFreeProfiles()
			//this.importSchemeAlter = true
		},

		addScheme() {
			this.$router.push({ name: 'editProfile', params: { id: 0 } })
		},

		addSchemeSave(state, name, schemeId) {
			var _this = this;
			_this.$http.post('/API/PLAT/operationScheme', {
				pid: _this.projectId,
				schemeId: schemeId,
				schemeName: name,
				state: state
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.addSchemeAlter = false;
						_this.getSchemeList()
					}
				})
		},

		//添加检查项table数组处理
		getExamineAlterMsg() {
			var _this = this
			if (_this.labelList.length < 21) { //不超过21个
				var arrLength = 21 - _this.labelList.length
				for (let i = 0; i < arrLength; i++) {
					_this.labelList.push({
						id: '',
						inspectName: ''
					})
				}
			} else if (_this.labelList.length % 3 != 0) {
				arrLength = 3 - (_this.labelList.length % 3);
				for (let i = 0; i < arrLength; i++) {
					_this.labelList.push({
						id: '',
						inspectName: ''
					})
				}
			}
		},
		//添加检查日
		addExamine() {
			var _this = this;
			_this.examine.examineList.unshift({
				id: '',
				isDelete: '1',
				timeOfAction: 0,
				windowNum: '1',
				windowType: '1', //1+-,2+,3-
				examineItemList: []
			})
		},
		//验证day不能重复不能为空，只能是数字
		dayValueChange(day) {
			var _this = this;
			var num = 0;
			for (let i = 0; i < _this.examine.examineList.length; i++) {
				if (_this.examine.examineList[i].timeOfAction == day && _this.examine.examineList[i].isDelete != 2) {
					num++;
				}
			}
			if (num > 1) {
				_this.defeated('DAY不能重复哦')
				_this.examineDay = false;
			} else if (day == '0') {
				_this.defeated('没有第0天哦')
				_this.examineDay = false;
			} else if (Number(_this.examine.workCycle) != 0 && Number(day) > Number(_this.examine.workCycle)) {
				_this.defeated('DAY不能大于访视周期哦')
				_this.examineDay = false;
			} else if (Number(_this.examine.workCycle) == 0) {
				_this.defeated('请先填写访视周期哦')
				_this.examineDay = false;
			} else {
				_this.examineDay = true;
			}
		},
		//复制检查日
		cloneExamine(item) {
			var list = JSON.parse(JSON.stringify(item.examineItemList)); //防止操作后数据同步
			//清空检查项的id
			for (let i = 0; i < list.length; i++) {
				list[i].id = ''
			}
			this.examine.examineList.unshift({
				id: '',
				isDelete: '1',
				timeOfAction: '',
				windowNum: item.windowNum,
				windowType: item.windowType, //1+-,2+,3-
				examineItemList: list
			})
		},
		//删除检查项
		removeExamine(item, index) {
			var _this = this;
			if (item.id) {
				item.isDelete = '2';
			} else {
				_this.examine.examineList.splice(index, 1)
			}
		},
		//点击检查项
		examinItemChange(item, index) {
			var _this = this;
			this.examineItemAlter = true;
			this.examinItemIndex = index;
			this.examineChoiceList = item.examineItemList;
			_this.$http.post('/API/PLAT/findAllInspectionItem', {
				pid: _this.projectId,
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						for (let i = 0; i < response.data.data.length; i++) {
							for (let j = 0; j < response.data.data[i].itemArray.length; j++) {
								response.data.data[i].itemArray[j].type = 1;
							}
						}
						_this.examineAlterMsg = response.data.data;
						_this.labelList = _this.examineAlterMsg[_this.classListActive].itemArray;
						_this.getExamineAlterMsg()
					}
				})
		},
		//检查项类切换
		examineItemClick(index) {
			var _this = this;
			this.classListActive = index;
			for (let i = 0; i < _this.examineAlterMsg.length; i++) {
				if (index == i) {
					_this.labelList = _this.examineAlterMsg[i].itemArray;
				}
			}
			_this.getExamineAlterMsg()
		},
		//点击检查项+号
		inspectNameClick(item) {
			//传参时id为关联id，itemId是id，此操作用于转换
			item.itemId = item.id;
			//				item.id='';
			this.examineChoiceList.push(item)
		},
		removeChoiceList(index, item) {
			var _this = this;
			if (item.id) {
				item.type = 2;
			} else {
				_this.examineChoiceList.splice(index, 1)
			}
		},
		//添加检查项弹框保存
		examineItemAlterSave() {
			var _this = this;
			var list = _this.examine.examineList;
			for (let i = 0; i < list.length; i++) {
				if (list[i].id == '0') {
					list[i].id = '';
				}
			}
			for (let i = 0; i < _this.examineChoiceList.length; i++) {
				if (_this.examineChoiceList[i].type == 1 && _this.examineChoiceList[i].id == _this.examineChoiceList[i].itemId) {
					_this.examineChoiceList[i].id = "";
				}

			}
			list[this.examinItemIndex].examineItemList = this.examineChoiceList;
			this.examine.examineList = list;
			this.examineItemAlter = false;
		},
		//方式周期验证
		changeWorkCycle() {
			var _this = this;
			for (let i = 0; i < _this.examine.examineList.length; i++) {
				if (Number(_this.examine.examineList[i].timeOfAction) > Number(_this.examine.workCycle)) {
					_this.defeated('访视周期不能小于检查日期DAY的最大日期')
					_this.examineDay = false;
				} else {
					_this.examineDay = true;
				}
			}
		},
		//访视保存
		examinAlertSava() {
			var _this = this;
			var res = /^[1-9]\d*$/;
			for (let i = 0; i < _this.examine.examineList.length; i++) {
				if (_this.examine.examineList[i].timeOfAction == '0') {
					_this.examineDay = false;
				}
			}
			if (!res.test(_this.examine.workCycle)) {
				_this.defeated('访视周期请填写数字')
			} else {
				if (!_this.examineDay) {
					_this.defeated('请输入正确的Day')
				} else {
					if (_this.examine.workName && _this.examine.workCycle && _this.examine.examineList.length > 0 && _this.examine.type) {
						_this.$http.post('/API/PLAT/addWork1', {
							schemeId: _this.examine.schemeId, //方案id
							workId: _this.examine.workId, //访视id
							workName: _this.examine.workName, //访视名称
							workCycle: _this.examine.workCycle, //访视周期
							type: _this.examine.type, //访视类型
							examineList: _this.examine.examineList //检查列表
						}, {
							headers: {
								"token": _this.token
							}
						})
							.then(function (response) {
								if (response.data.code == 200) {
									_this.succeed('保存成功')
									setTimeout(function () {
										_this.getSchemeList()
										_this.interviewRight = true;
									}, 500)
								} else {
									_this.defeated(response.data.detail)
								}
							})
					} else {
						_this.defeated('请填写完整信息')
					}
				}
			}
		},
		//获取导入文件名称
		getFile(e) {
			this.fileValue = e.name;
		},
		//获取导入文件名称
		getFile1(e) {
			var _this = this;
			if (e.code == 200) {
				_this.fileValue2 = e.data.name;
			}
		},
		importSchemeSave() {
			var _this = this;
			if (_this.importSchemeSaveisclick) {
				_this.importSchemeSaveisclick = false;
				_this.fullscreenLoading = true;
				_this.$http.post('/API/PLAT/uplaodSchemeExcel', {
					fileUrl: _this.fileValue2,
					pid: _this.projectId
				}, {
					headers: {
						"token": _this.token
					}
				})
					.then(function (response) {
						if (response.data.code == 200) {
							_this.succeed('导入成功')
							_this.getSchemeList()
							_this.importSchemeAlter = false;
							_this.fullscreenLoading = false;
							setTimeout(function () {
								_this.importSchemeSaveisclick = true;
							}, 500)
						} else if (response.data.code == 301) {
							_this.defeated(response.data.msg)
							_this.fullscreenLoading = false;
							setTimeout(function () {
								_this.importSchemeSaveisclick = true;
							}, 500)
						} else {
							_this.importSchemeAlter = false;
							_this.importSchemeErrAlter = true;
							_this.importSchemeErrAlterList = response.data.data;
							_this.fullscreenLoading = false;
							setTimeout(function () {
								_this.importSchemeSaveisclick = true;
							}, 500)
						}
					})
			}
		},
		formatDate(date) {
			var y = date.getFullYear();
			var m = date.getMonth() + 1;
			m = m < 10 ? ('0' + m) : m;
			var d = date.getDate();
			d = d < 10 ? ('0' + d) : d;
			var h = date.getHours();
			var minute = date.getMinutes();
			minute = minute < 10 ? ('0' + minute) : minute;
			var second = date.getSeconds();
			second = minute < 10 ? ('0' + second) : second;
			return y + '-' + m + '-' + d;
		},
	},
}
